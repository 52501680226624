.LoginFooter {
  display: flex;
  justify-content: space-between;
}

.LoginFooter button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #e7e7e7;
}

.RememberUserDiv {
  display: flex;
  align-items: center;
  gap: 6px;
}

.CustomCheckbox {
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #ffffff;
  border-radius: 3px;

  &:checked {
    background-color: #eea23f;
    border-color: #ffffff;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #eea23f;
    border-radius: 2px;
    display: none;
  }

  &:checked:before {
    display: block;
  }
}

.FormDiv {
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #ffffff14;
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  border: 1px solid #e7e7e7;
  box-shadow: 1px 1px 5px 1px #ffffff4d inset,
    0px 109.54px 82.16px 0px #0000000d;
}

.Input {
  width: 100%;
  outline: none;
  border: none;
  color: white;
  padding: 16px;
}

.Input::placeholder {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.109px;
  color: #e7e7e7;
}

.LoginButton {
  color: #e7e7e7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 48px;
  gap: 8px;
  border-radius: 0px 0px 8px 8px;
  background: #e7e7e752;
}

.LoginButton:hover {
  background: #e7e7e733;
  transition: 0.6s;
}

.LoginButton p {
  font-size: 14px;
  font-weight: 400;
}

.ViewPasswordButton {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.ForgotPasswordButton:hover {
  transition: 0.6s;
  color: #eea23fe9;
}

.ErrorMessage {
  font-size: 14px;
  font-weight: 300;
  color: #e7e7e7;
  margin-bottom: 1px;
}
