.Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  background: linear-gradient(
      180deg,
      rgba(25, 28, 32, 0.9) 0%,
      rgba(25, 28, 32, 0) 75.15%
    ),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0) 61.76%,
      rgba(25, 28, 32, 0.9) 100%
    ),
    linear-gradient(90deg, rgba(25, 28, 32, 0.9) 0%, rgba(25, 28, 32, 0) 59.71%),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0) 60.43%,
      rgba(25, 28, 32, 0.9) 100%
    );

  padding: 24px 80px;
}

.Header {
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderRightSideDiv {
  display: flex;
  align-items: center;
}

.ButtonsDiv {
  display: flex;
  align-items: center;
}

.ButtonsDiv button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #f6f6f6;
}

.ButtonsDiv button:hover {
  color: #f6f6f6ad;
  transition: 0.4s;
}

.ButtonsDiv div {
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 28px;
  border-right: 1.5px solid #ffffff29;
}

.RoutesDiv {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.RoutesDiv button {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #f6f6f6;
}

.BeesFooterLogoDiv {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
}

.BeesFooterLogoDiv h2 {
  font-size: 15.86px;
  font-weight: 400;
  color: #eea23f;
}

.BeesLogo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.LoginContainerWrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginContainer {
  width: 500px;
  display: flex;
  flex-direction: column;

  @media (max-width: 540px) {
    width: 90%;
  }
}

.LoginTitleDiv {
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;
}

.LoginTitleDiv h1 {
  font-size: 30px;
  font-weight: 400;
  color: #f6f6f6;
}

.LoginTitleDiv p {
  font-size: 15px;
  font-weight: 400;
  color: #f6f6f6;
}

.LoginFooter {
  display: flex;
  justify-content: space-between;
}

.LoginFooter button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #e7e7e7;
}

.CloseButton {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    right: 10px;
    top: 14px;
  }
}
