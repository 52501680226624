.ContainerWrapper {
  position: relative;
  width: 100%;
}

.VideoBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Container {
  position: relative;
  width: 100%;
  height: 100%;

  background: radial-gradient(50% 50% at 50% 50%, #6975866d 0%, #191c20e0 99.5%),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0.72) 0%,
      rgba(25, 28, 32, 0) 16.15%
    ),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0) 83.85%,
      rgba(25, 28, 32, 0.72) 100%
    ),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0.72) 0%,
      rgba(25, 28, 32, 0) 59.71%
    ),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0) 60.43%,
      rgba(25, 28, 32, 0.72) 100%
    );

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  @media (max-width: 680px) {
    padding: 15px;
  }
}

.SectionContentDiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 132px;

  @media (max-width: 990px) {
    gap: 40px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.InformationDiv {
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 523px;

  @media (max-width: 760px) {
    width: 100%;
  }
}

.InformationDivHeader {
  display: flex;
  flex-direction: column;

  @media (max-width: 760px) {
    align-items: center;
  }
}

.InformationDivTitle {
  display: flex;
  flex-direction: column;
  font-size: 38px;
  font-weight: 400;
  text-align: left;
  line-height: 44px;
  color: #ffffff;

  @media (max-width: 760px) {
    text-align: center;
  }
}

.InformationDivDescription {
  font-size: 17px;
  font-weight: 400;
  line-height: 21.67px;
  text-align: left;
  color: #ffffff;

  @media (max-width: 760px) {
    text-align: center;
  }
}

.InformationDivBotom {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.EmailDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

.EmailText {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
}
