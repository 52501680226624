.LocationsDiv {
  display: flex;
  gap: 26px;
}

.LocationCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.LocationTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.86px;
  text-align: left;
  color: #f3f3f3;
}

.LocationInformationDiv {
  display: flex;
  gap: 8px;
}

.LocationInformationTextDiv {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.LocationInfomationText {
  font-size: 10px;
  font-weight: 400;
  line-height: 12.04px;
  text-align: left;
  color: #ffffff;
}
