.Container {
  width: 100%;
  padding-bottom: 100px;
  background: #191c20;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContentDiv {
  width: 100%;
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 680px) {
    gap: 25px;
  }
}

.HeaderTextsDiv {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;

  @media (max-width: 680px) {
    width: calc(100% - 6px);
    gap: 40px;
  }
}

.HeaderTitleDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.HeaderTitleDiv img {
  width: 200px;
  height: 54px;

  @media (max-width: 680px) {
    width: 150px;
    height: 54px;
  }
}

.HeaderTitle {
  font-size: 31.5px;
  font-weight: 400;
  color: #eea23f;

  @media (max-width: 680px) {
    font-size: 26px;
  }
}

.HeaderDescription {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.67px;
  text-align: center;

  @media (max-width: 680px) {
    font-size: 13px;
    line-height: 16px;
  }
}

.DashboardImageDiv {
  img {
    width: 100%;
  }
}
