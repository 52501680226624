.ContainerWrapper {
  position: relative;
  width: 100%;
  height: calc(100dvh + 100px);
}

.VideoBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Container {
  position: relative;
  width: 100%;
  height: 100.1%;
  padding-bottom: 100px;
  background: radial-gradient(50% 50% at 50% 50%, #6975866d 0%, #191c20e0 99.5%),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0.72) 0%,
      rgba(25, 28, 32, 0) 16.15%
    ),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0) 83.85%,
      rgba(25, 28, 32, 0.72) 100%
    ),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0.72) 0%,
      rgba(25, 28, 32, 0) 59.71%
    ),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0) 60.43%,
      rgba(25, 28, 32, 0.72) 100%
    );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SliderContainer {
  position: relative;
  width: calc(100% + 10px);
  padding: 26px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #00000029;
  box-shadow: 3.48px 1.74px 13.9px 13.9px #0000003d,
    0.87px 0.87px 4.34px 0.87px #ffffffa3 inset;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.SlideNavigationButton {
  position: absolute;
  z-index: 99;
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 16px;
  border-radius: 100px;
  border: none;
  background: #ffffff14;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: 1px 1px 5px 1px #ffffff52 inset, 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScheduleButtonDiv {
  position: absolute;
  top: 0;
}

.PaginationDiv {
  position: absolute;
  bottom: -36px;
}
