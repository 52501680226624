.AllContentWrapper {
  max-width: 100dvw;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #000000;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(37, 37, 37);
  }

  &::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #616161;
  }
}
