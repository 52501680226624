.SlideContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SlideVideo {
  width: 100%;
  object-fit: fill;
  user-select: none;
}

.SlideWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.VideoInformationWrapper {
  padding: 20px 10px 40px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.VideoInformationWrapper h1 {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}

.VideoInformationWrapper p {
  font-size: 14px;
  font-weight: 400;
  color: #f3f3f3;
}
