.ScrollUpButton {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff14;
  border: none;
  box-shadow: 1px 1px 5px 1px #ffffff52 inset, 0px 4px 4px 0px #00000040;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.ScrollUpButton:hover {
  background-color: #eea23fe9;
  transition: 0.6s;
}
