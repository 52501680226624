.Header {
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderRightSideDiv {
  display: flex;
  align-items: center;
}

.ButtonsDiv {
  display: flex;
  align-items: center;
}

.ButtonsDiv button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #f6f6f6;
}

.ButtonsDiv button:hover {
  color: #f6f6f6ad;
  transition: 0.4s;
}

.ButtonsDiv div {
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 28px;
  border-right: 1.5px solid #ffffff29;
}
