.Container {
  padding: 30px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background: #191c20;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 680px) {
    padding: 20px;
    padding-top: 80px;
  }
}

.ContentDiv {
  width: 917px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
  width: 100%;
}

.HeaderTextsDiv {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  margin-bottom: 20px;

  @media (max-width: 680px) {
    width: 100%;
  }
}

.HeaderTitle {
  font-size: 38px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;

  @media (max-width: 680px) {
    line-height: 40px;
  }
}

.HeaderDescription {
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  color: #ffffff;
}

.DevicesImageDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.DevicesImageDiv img {
  height: 63dvh;

  @media (max-width: 1076px) {
    height: auto;
    width: 100%;
  }
}

.InformationDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 305px;
  gap: 16px;
}

.InformationDivText {
  font-size: 12px;
  font-weight: 200;
  line-height: 19.25px;
  text-align: left;
  color: #ffffffc1;

  @media (max-width: 680px) {
    line-height: 14px;
  }
}
