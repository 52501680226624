.FormDiv {
  width: 574px;
  border-radius: 16px;
  background: #ffffff14;
  box-shadow: 0px 109.54px 82.16px 0px #0000000d,
    1px 1px 5px 1px #ffffffa3 inset;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 16px;

  @media (max-width: 990px) {
    width: auto;
  }
}

.FormHeaderDiv {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
}

.FormHeaderText {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.09px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #f6f6f6;
}

.FormInputsDiv {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.NameInputsDiv {
  display: flex;
  gap: 20px;
}

.SelectInputDiv {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.SelectInputText {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.45px;
  text-align: left;
  color: #ffffff;
}

.Input {
  outline: none;
  width: 100%;
  padding: 10px;
  background-color: #ff000000;
  color: #ffffff;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #ffffff;
}

.Input:hover,
.Input:focus {
  box-shadow: 0px 0px 6px #ffffffad;
}

.Input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.86px;
  text-align: left;
  color: #ffffff;
}

.MessageInput {
  outline: none;
  width: 100%;
  padding: 10px;
  background-color: #ff000000;
  color: #ffffff;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #ffffff;
}

.MessageInput:hover,
.MessageInput:focus {
  box-shadow: 0px 0px 6px #ffffffad;
}

.MessageInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.86px;
  text-align: left;
  color: #ffffff;
}

.InputError {
  outline: none;
  width: 100%;
  padding: 10px;
  background-color: #ff000000;
  color: #ff8c8c;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #ff8c8c;
}

.InputError:hover,
.InputError:focus {
  box-shadow: 0px 0px 6px #ffb9b9ad;
}

.InputError::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.86px;
  text-align: left;
  color: #ff8c8c;
}

.SelectionInputsDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ContactsDiv {
  display: flex;
  gap: 30px;

  @media (max-width: 540px) {
    flex-direction: column;
    gap: 10px;
  }
}

.ContactOptionDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ContactOptionsWrapper {
  display: flex;
  gap: 30px;
  align-items: center;
}

.ContactTypeText {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.ContactTypeTextError {
  color: #ff8c8c;
}

.CustomCheckbox {
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ffffff;
  border-radius: 5px;

  &:checked {
    background-color: #eea23f;
    border-color: #ffffff;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #eea23f;
    border-radius: 2px;
    display: none;
  }

  &:checked:before {
    display: block;
  }
}

.CustomCheckboxError {
  border: 2px solid #ff8c8c;
}

.AgreementDiv {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.TermsText {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #bcbdbf;
}

.SubmitButton {
  margin-top: 30px;
  margin-bottom: 6px;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  padding: 16px;
  border-radius: 100px;
  gap: 16px;
  background: #ffffff;
  cursor: pointer;

  &:hover {
    background: #ffffffd1;
    transition: 0.3s;
  }
}

.SubmitButtonContentDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

.SubmitButtonText {
  font-size: 14px;
  font-weight: 500;
  color: #2a3136;
}
