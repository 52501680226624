@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html,
body {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

div {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.CustomSwiperPagination {
  .swiper-pagination-bullet {
    opacity: 1;
    background: #727272bf;
  }

  .swiper-pagination-bullet-active {
    width: 64px;
    height: 8px;
    border-radius: 16px;
    border: none;
    background: #ffffff63;
  }
}
