.RouteButton {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.RouteButton:hover {
  color: #ffffffc1;
  transition: 0.3s;
}
