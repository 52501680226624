.SlideContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SlideImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.SlideWrapper {
  position: relative;
  width: 1350px;
  height: 801px;
  border-radius: 14px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(42, 49, 54, 0.64) 0%,
        rgba(42, 49, 54, 0) 16.15%
      ),
      linear-gradient(
        180deg,
        rgba(42, 49, 54, 0) 83.85%,
        rgba(42, 49, 54, 0.64) 100%
      ),
      linear-gradient(
        90deg,
        rgba(42, 49, 54, 0.32) 0%,
        rgba(42, 49, 54, 0) 25.7%
      ),
      linear-gradient(
        90deg,
        rgba(42, 49, 54, 0) 72.4%,
        rgba(42, 49, 54, 0.32) 100%
      );
    z-index: 1;
  }

  @media (max-height: 1060px) {
    width: 1080px;
    height: 640px;
  }

  @media (max-height: 860px) {
    width: 900px;
    height: 534px;
  }

  @media (max-height: 710px) {
    width: 750px;
    height: 445px;
  }

  @media (max-height: 610px) {
    width: 642px;
    height: 381px;
  }

  @media (max-width: 1150px) {
    width: 750px;
  }

  @media (max-width: 1026px) {
    width: 642px;
  }

  @media (max-width: 896px) {
    width: 500px;
  }

  @media (max-width: 744px) {
    width: 400px;
  }

  @media (max-width: 620px) {
    width: 360px;
  }

  @media (max-width: 588px) {
    width: 320px;
  }
}
