.ScheduleDemoButton {
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  padding: 12px 30px;
  border-radius: 1000px;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(61, 66, 68, 0) -40.91%,
    #202429 132.95%
  );
  box-shadow: 4px 2px 16px 16px #0000003d, 1px 1px 5px 1px #ffffffa3 inset;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}

.ScheduleDemoButton:hover {
  background: #eea23fb9;
  transition: 0.6s;
}

.DemoButtonText {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}
