.Container {
  padding: 100px 0 15px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #191c20;
}

.CopyrightDiv {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  border-top: 1px solid #30363d;
}

.CopyrightText {
  font-size: 12px;
  font-weight: 400;
  color: #808fa3;
}

.FooterContentDiv {
  padding-right: 100px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BeesLogoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.BeesLogoImg {
  width: 183px;
  height: 48px;
}

.BeesLogoText {
  font-size: 12.6px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #eea23f;
}

.ContentDiv {
  display: flex;
  gap: 80px;
}

.RouteDivContainer {
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.RouteDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.RouteDivTitle {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #606b7a;
}

.RouteButtonsDiv {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.RouteButton {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.RouteButton:hover {
  color: #ffffffc1;
  transition: 0.3s;
}
