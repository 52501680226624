.Container {
  position: relative;
  width: 100%;
  height: 100%;

  background: linear-gradient(
      180deg,
      rgba(25, 28, 32, 0.9) 0%,
      rgba(25, 28, 32, 0) 16.15%
    ),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0) 61.76%,
      rgba(25, 28, 32, 0.9) 100%
    ),
    linear-gradient(90deg, rgba(25, 28, 32, 0.9) 0%, rgba(25, 28, 32, 0) 59.71%),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0) 60.43%,
      rgba(25, 28, 32, 0.9) 100%
    );

  padding: 24px 80px;

  @media (max-width: 870px) {
    padding: 10px;
  }
}

.CenterTitleDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.CenterTitleDiv h1 {
  font-family: 'AauxProBold', sans-serif;
  font-size: 80px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: center;
  color: #f6f6f6;

  @media (max-width: 1187px) {
    font-size: 60px;
  }

  @media (max-width: 900px) {
    font-size: 40px;
  }
}

.ScrollDownDiv {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 64px;
    gap: 8px;
  }
}

.ScrollDownDiv p {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.65px;
  text-align: center;
  color: #ffffff;
}
