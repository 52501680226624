.ContainerWrapper {
  width: 100%;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #191c20;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(37, 37, 37);
  }

  &::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #616161;
  }
}

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-height: 744px) and (max-width: 502px) {
    height: unset;
  }
}

.HeaderWrapper {
  padding: 20px 80px 0 80px;

  @media (max-width: 900px) {
    padding: 10px 10px 0 10px;
  }
}

.ContentDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 70px 80px 0 80px;

  @media (max-width: 900px) {
    padding: 40px 20px 0 20px;
  }
}

.LeftSideDiv {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  max-height: 800px;
  gap: 30px;
}

.FirstLeftSideContentDiv {
  display: flex;
  flex-direction: column;
}

.InformationDivTitle {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
}

.InformationDivDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

.InformationDivBotom {
  display: flex;
  flex-direction: column;
  gap: 37px;
  padding: 24px;
}

.SecondLeftSideContentDiv {
  display: flex;
  flex-direction: column;
}

.EmailLink {
  cursor: pointer;
  color: #eea23f;
  text-decoration: underline;
}

.EmailLink:hover {
  cursor: pointer;
  color: #ffc273;
  text-decoration: underline;
}

.RightSideDiv {
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-height: 700px;
}

.RightSideFirstContentDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.LocationInformationDiv {
  display: flex;
  gap: 8px;
}

.LocationInformationTextDiv {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.LocationInfomationText {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.26px;
  color: #ffffff;
}

.RightSideTitle {
  font-size: 30px;
  font-weight: 400;
  color: #ffffff;
}

.EmailDiv {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 14px;
}

.EmailText {
  color: #eea23f;
}

.RightSideSecondContentDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
