.UserButtonDiv {
  position: relative;
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.UserProfileButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 16px;
  border-radius: 1000px;
  background: #ffffff14;
  backdrop-filter: blur(12px);
  border: 2px solid #f3f3f3;
}

.OpenProfileOptionsButton {
  cursor: pointer;
  background: none;
  border: none;
}

.ProfileOptionsDiv {
  position: absolute;
  top: 44px;
  right: 0;
  width: 97px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 8px;
  background: #ffffff14;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border: none;
  box-shadow: 1px 1px 5px 1px #ffffffa3 inset;
}

.ProfileOptionsDiv button {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  color: #ffffff;
}

.ProfileOptionsDiv button:hover {
  color: #f6f6f6ad;
  transition: 0.4s;
}
