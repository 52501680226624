.ContentDiv {
  position: relative;
  overflow: hidden;
  width: calc(100% - 16px);
  border-radius: 16px;
  background: #00000029;
  border: 1px solid #ffffff3c;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  @media (min-width: 1100px) and (min-height: 857px) {
    width: 1100px;
  }

  @media (min-width: 1303px) and (min-height: 966px) {
    width: 1200px;
  }
}

.MainVideo {
  width: 100%;
}

.BottomVideoContentDiv {
  position: absolute;
  bottom: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PaginationDiv {
  padding-bottom: 6px;
}

.VideosDiv:active {
  cursor: grabbing;
}
